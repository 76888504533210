import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { ToastContainer } from "@interstate/components/Toast";
import { ConversationContext, ConversationDetailsContext } from "./context";
import { ConversationPaneHeader } from "./conversation-pane-header";
import { ReplyApp } from "./reply/app";
import { LoadingIndicator } from "@interstate/components/LoadingIndicator";
import {
  ConversationItem,
  ConversationTypeEnum,
  ReplyOption,
  SendCommunicationAttachmentResponse
} from "@coxauto-ui/communications-interfaces";
import ConversationPaneTabs from "./conversation-pane-tabs";
import { conversationListError, conversationListTestId } from "./testIds";
import { MessageItemDetails } from "./list/item-details";
import ConversationPaneContent from "./conversation-pane-content";
import OpenReplyButton from "./reply/open-reply-button";
import { XMarkIcon } from "@interstate/components/Icons";
import { Button } from "@interstate/components/Button";
import { GenerativeAI } from "@coxauto-ui/generative-ai";
import { deriveDisplayTextForReplyHeader } from "@coxauto-ui/communications-util";

const StyledConversationPane = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #fff;

  .cx-tabs__content {
    display: none;
  }

  .conversation-icon.gray {
    color: #959595;
  }

  .conversation-icon.red {
    color: #d41e27;
  }

  .conversation-icon.green {
    color: #479230;
  }

  .conversation-icon.blue {
    color: #2372b8;
  }

  .conversation-icon.orange {
    color: #f87216;
  }

  .item {
    .message-item {
      padding-top: 0.75em;
      padding-bottom: 0.75em;
      word-wrap: break-word;
    }

    .message-item-bubble {
      border-radius: 0.3em;
      cursor: pointer;
      padding: 0.5em;
      display: inline-block;
      max-width: 85%;
      text-align: left;
      min-width: min-content;
    }

    .message-item-bubble.full {
      width: 85%;
    }
    .message-item-inbound {
      text-align: left;
    }

    .message-item-inbound-bubble {
      background-color: #fff;
      color: #000;
      margin-bottom: 4px;
    }

    .message-item-attachment-inbound-bubble {
      padding: 0em;
      margin-bottom: 4px;
    }

    .message-item-attachment-inbound-view {
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
    }

    .message-item-outbound {
      text-align: right;
    }

    .message-item-outbound-bubble {
      background-color: #cce8ff;
      color: #000;
      margin-bottom: 4px;
    }

    .message-item-outbound-bubble-error {
      background-color: #e3e3e3;
      color: #5a5a5a;
    }

    .message-item-attachment-outbound-bubble {
      padding: 0em;
      margin-bottom: 4px;
    }

    .message-item-attachment-outbound-view {
      margin-top: 5px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-end;
    }

    .attachment-view-container {
      border-radius: 8px;
      width: 150px;
      height: 150px;
      background-color: #e3e3e3;
      box-shadow: 0 0 4px 0 #b2b2b2;
      cursor: pointer;
    }

    .attachment-view-thumbnail-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #959595;

      .attachment-view-thumbnail {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .fallback-container {
        text-align: center;
      }

      .photo-icon {
        font-size: xx-large;
      }

      .error-message {
        margin-top: 0;
        font-size: 10px;
      }

      .attachment-view-thumbnail-download-container {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .attachment-audio-container {
      height: 48px;
      padding: 10px;
    }

    .attachment-play-audio-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .loading-audio {
        text-align: center;
      }

      .audio-player {
        display: block;
        cursor: auto;
        height: 2em;
      }

      .audio-with-download-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
      }
    }
  }
`;

const StyledReplyApp = styled.div`
  position: relative;
  background-color: #fff;

  #customer-inbox-reply-window-header {
    display: flex;
    margin: 8px;
    align-items: center;

    #customer-inbox-reply-window-close-icon-wrapper {
      cursor: pointer;
      margin-left: auto;
    }

    .spacer {
      border-right: 1px solid #d8d8d8;
      width: 0;
      height: 12px;
      margin: 0 8px;
    }

    h4 {
      margin: 0;
    }
  }
`;

const StyledFloatingButton = styled.div`
  position: absolute;
  bottom: 16px;
  right: 24px;
`;

const StyledGenerativeAIWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const supportedReplyTypes = [
  ConversationTypeEnum.EMAIL,
  ConversationTypeEnum.PHONE,
  ConversationTypeEnum.TEXT
];

export function ConversationPane() {
  const { customerName, error, occConfig } = useContext(ConversationContext);
  const [conversationItems, setConversationItems] = useState<
    ConversationItem[]
  >([]);
  const [displayConversationItems, setDisplayConversationItems] = useState<
    ConversationItem[]
  >([]);
  const [selectedReplyOption, setSelectedReplyOption] =
    useState<ReplyOption | null>(null);
  const [selectedConversationItem, setSelectedConversationItem] =
    useState<ConversationItem | null>(null);
  const [replyText, setReplyText] = useState(occConfig.messageContent ?? "");
  const [replyTextSubject, setReplyTextSubject] = useState("");
  const [canLoadList, setCanLoadList] = useState(false);
  const [latestConversationItemType, setLatestConversationItemType] =
    useState<ConversationTypeEnum | null>(null);
  const [currentConversationType, setCurrentConversationType] =
    useState<ConversationTypeEnum | null>(null);
  const [recentContactEmail, setRecentContactEmail] = useState("");
  const [recentContactText, setRecentContactText] = useState("");
  const [xSignature, setXSignature] = useState("");
  const [selectedChannelValues, setSelectedChannelValues] = useState<Map<
    ConversationTypeEnum,
    string
  > | null>(null);
  const [selectedFromTextingPhoneNumber, setSelectedFromTextingPhoneNumber] =
    useState("");
  const [messageCallContacted, setMessageCallContacted] = useState("");
  const [messageCallType, setMessageCallType] = useState("");
  const [messageAttachments, setMessageAttachments] = useState<
    SendCommunicationAttachmentResponse[]
  >([]);
  const [showReply, setShowReply] = useState(false);
  const [showGenerativeAI, setShowGenerativeAI] = useState(false);
  const [isSuggestEdit, setIsSuggestEdit] = useState(false);
  const {
    customerId,
    customerSystem,
    env,
    isGenerativeAIEnabled,
    getUserName
  } = useContext(ConversationContext);

  useEffect(() => {
    setCanLoadList(customerName?.trim().length > 0);
  }, [customerName]);

  useEffect(() => {
    setReplyText(occConfig.messageContent ?? "");
  }, [occConfig]);

  useEffect(() => {
    setShowReply(false);
  }, [currentConversationType]);

  const providerValue = useMemo(() => {
    return {
      conversationItems,
      currentConversationType,
      displayConversationItems,
      latestConversationItemType,
      messageAttachments,
      messageCallContacted,
      messageCallType,
      recentContactEmail,
      recentContactText,
      replyText,
      replyTextSubject,
      selectedChannelValues,
      selectedConversationItem,
      selectedFromTextingPhoneNumber,
      selectedReplyOption,
      xSignature,
      setConversationItems,
      setCurrentConversationType,
      setDisplayConversationItems,
      setLatestConversationItemType,
      setMessageAttachments,
      setMessageCallContacted,
      setMessageCallType,
      setRecentContactEmail,
      setRecentContactText,
      setReplyText,
      setReplyTextSubject,
      setSelectedChannelValues,
      setSelectedConversationItem,
      setSelectedFromTextingPhoneNumber,
      setSelectedReplyOption,
      setXSignature
    };
  }, [
    conversationItems,
    currentConversationType,
    displayConversationItems,
    latestConversationItemType,
    messageAttachments,
    messageCallContacted,
    messageCallType,
    recentContactEmail,
    recentContactText,
    replyText,
    replyTextSubject,
    selectedChannelValues,
    selectedConversationItem,
    selectedFromTextingPhoneNumber,
    selectedReplyOption,
    xSignature
  ]);

  const toggleShowReply = () => {
    setShowReply(b => !b);
  };

  const onGenerateReplyClick = () => {
    setIsSuggestEdit(false);
    setShowGenerativeAI(true);
  };

  const onSuggestEditClick = () => {
    setIsSuggestEdit(true);
    setShowGenerativeAI(true);
  };

  const closeGenerativeAI = () => {
    setShowGenerativeAI(false);
  };

  const userName = useMemo(() => getUserName(), [getUserName]);

  const insertGeneratedResponse = (message: string) => {
    setReplyText(message);
    setShowGenerativeAI(false);
  };

  if (error) {
    return (
      <span data-testid={conversationListError} id={conversationListError}>
        {error}
      </span>
    );
  }

  return (
    <ConversationDetailsContext.Provider value={providerValue}>
      {(canLoadList && (
        <StyledConversationPane data-testid={conversationListTestId}>
          <ToastContainer />
          <ConversationPaneHeader />
          {!selectedConversationItem && <ConversationPaneTabs />}
          {!selectedConversationItem && (
            <ConversationPaneContent
              isFullScreenReplyWindow={
                currentConversationType === ConversationTypeEnum.EMAIL ||
                currentConversationType === ConversationTypeEnum.PHONE
              }
              showReply={showReply}
            />
          )}
          {selectedConversationItem && <MessageItemDetails />}
          {showReply ? (
            <StyledReplyApp>
              <div id="customer-inbox-reply-window-header">
                <h4>
                  {deriveDisplayTextForReplyHeader(
                    currentConversationType || ConversationTypeEnum.NONE
                  )}
                </h4>
                {isGenerativeAIEnabled &&
                  (currentConversationType === ConversationTypeEnum.EMAIL ||
                    currentConversationType === ConversationTypeEnum.TEXT) && (
                    <>
                      <span className="spacer" />
                      <Button buttonStyle="aux" onClick={onGenerateReplyClick}>
                        Generate Reply
                      </Button>
                      <span className="spacer" />
                      <Button buttonStyle="aux" onClick={onSuggestEditClick}>
                        Suggest Edit
                      </Button>
                    </>
                  )}
                <span
                  data-testid="customer-inbox-reply-window-close-icon-wrapper"
                  id="customer-inbox-reply-window-close-icon-wrapper"
                  onClick={toggleShowReply}
                >
                  <XMarkIcon />
                </span>
              </div>
              <ReplyApp />
            </StyledReplyApp>
          ) : (
            currentConversationType &&
            supportedReplyTypes.includes(currentConversationType) && (
              <StyledFloatingButton>
                <OpenReplyButton
                  conversationType={
                    currentConversationType || ConversationTypeEnum.NONE
                  }
                  onOpenReplyButtonClick={toggleShowReply}
                />
              </StyledFloatingButton>
            )
          )}
          {showGenerativeAI &&
            (currentConversationType === ConversationTypeEnum.EMAIL ||
              currentConversationType === ConversationTypeEnum.TEXT) && (
              <StyledGenerativeAIWrapper>
                <GenerativeAI
                  channelType={currentConversationType}
                  closeGenerativeAI={closeGenerativeAI}
                  customerId={customerId}
                  customerSystem={customerSystem}
                  env={env}
                  initialPrompt={isSuggestEdit ? replyText : ""}
                  insertGeneratedResponse={insertGeneratedResponse}
                  isSuggestEdit={isSuggestEdit}
                  userDisplayName={userName}
                />
              </StyledGenerativeAIWrapper>
            )}
        </StyledConversationPane>
      )) || (
        <LoadingIndicator
          htmlId="conversation-pane-loading-indicator"
          text="Loading Communications"
        />
      )}
    </ConversationDetailsContext.Provider>
  );
}

export default ConversationPane;
