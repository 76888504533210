import {
  AttachmentTypeEnum,
  ContactDetail,
  Conversation,
  ConversationDirection,
  ConversationItem,
  ConversationTypeEnum,
  Environment,
  EventResultEnum,
  ReplyOption,
  SystemOfRecord
} from "@coxauto-ui/communications-interfaces";
import {
  IConversationDetailsContext,
  ICustomerConversationContext
} from "./context";

export const mockReplyOptions: ReplyOption[] = [
  {
    // detail: "testemail@testemail.com",
    replyInline: true,
    replyUrl: "https://www.coxautoinc.com/",
    type: ConversationTypeEnum.EMAIL
  },
  {
    // detail: "+1 (310) 885-4602",
    replyInline: false,
    replyUrl: "https://www.coxautoinc.com/",
    type: ConversationTypeEnum.TEXT
  },
  {
    // detail: "+1 (310) 885-4602",
    replyInline: false,
    replyUrl: "https://www.coxautoinc.com/",
    type: ConversationTypeEnum.PHONE
  },
  {
    // detail: "inline",
    replyInline: true,
    type: ConversationTypeEnum.CHAT
  }
];

export const attachmentScratchpad = [
  {
    key: "MyFileKey",
    contentType: "image/jpeg",
    fileName: "TheBestFile.jpeg",
    fileSize: 14,
    token: "ThisIsMySecureToken"
  },
  {
    key: "MyFileKey2",
    contentType: "image/jpeg",
    fileName: "TheBestFile2.jpeg",
    fileSize: 16,
    token: "ThisIsMySecureToken2"
  }
];

export const onClickMessage = async (selectedMessage: ConversationItem) => {
  return;
};

export const getAdditionalMessageDetail = async (key: number) => {
  return new Promise<string>(resolve =>
    resolve(
      "This is where the additional message content would be if we were really calling an API, but this is just storybook so you only get this"
    )
  );
};
export const conversationDataProps: Conversation = {
  nextItem: 8,
  conversation: [
    {
      age: "delivered 19 min ago",
      direction: ConversationDirection.Outbound,
      content: { text: "Hello, how can I help?" },
      senderName: "Chicken Mc Nugget",
      senderContactInfo: "",
      date: "03-14-2024 02:09:32",
      type: ConversationTypeEnum.TEXT,
      key: 0,
      phoneNumber: "",
      emailAddress: "",
      eventResult: EventResultEnum.SUCCESS,
      attachments: []
    },
    {
      age: "17 min ago",
      direction: ConversationDirection.Inbound,
      content: {
        text: "Hello! I would like to know how much my car is worth as a trade in, can we do a phone call?"
      },
      senderName: "Mike Heady",
      senderContactInfo: "",
      date: "03-14-2024 02:10:32",
      type: ConversationTypeEnum.TEXT,
      key: 1,
      phoneNumber: "",
      emailAddress: "",
      eventResult: EventResultEnum.SUCCESS,
      attachments: [
        {
          attachmentId: "1212",
          fileName: "MyFile.jpg",
          type: AttachmentTypeEnum.IMAGE
        },
        {
          attachmentId: "",
          fileName: "",
          type: AttachmentTypeEnum.VIDEO,
          url: "https://mock-testing-player.vin.media/?src=https%3a%2f%2fqa.video.vin.media%2fCxm-Occ-Bff%2fshort%2f4f402b97-55b3-4c2d-a970-e37fe459a131"
        },
        {
          attachmentId: "1213",
          fileName: "MyFile.mp4",
          type: AttachmentTypeEnum.AUDIO
        }
      ]
    },
    {
      age: "delivered 16 min ago",
      direction: ConversationDirection.Outbound,
      content: {
        text: "Sure we can set up a phone call, do you have some time right now?"
      },
      senderName: "Nick Bargeman",
      senderContactInfo: "",
      date: "03-14-2024 02:11:32",
      type: ConversationTypeEnum.TEXT,
      key: 2,
      phoneNumber: "",
      emailAddress: "",
      eventResult: EventResultEnum.SUCCESS,
      attachments: [
        {
          attachmentId: "1213",
          fileName: "MyFile.jpg",
          type: AttachmentTypeEnum.IMAGE
        }
      ]
    },
    {
      age: "22 min ago",
      direction: ConversationDirection.Inbound,
      content: {
        text: "Inbound call",
        callUrl:
          "https://filesamples.com/samples/audio/mp3/Symphony%20No.6%20(1st%20movement).mp3"
      },
      senderName: "Mike Heady",
      senderContactInfo: "",
      date: "03-14-2024 02:12:32",
      type: ConversationTypeEnum.PHONE,
      key: 3,
      phoneNumber: "",
      emailAddress: "",
      eventResult: EventResultEnum.SUCCESS,
      attachments: []
    },
    {
      age: "22 min ago",
      direction: ConversationDirection.Outbound,
      content: {
        text: "Outbound call from banana phone",
        callUrl: "https://filesamples.com/samples/audio/mp3/sample1.mp3"
      },
      senderName: "Michelle Foster",
      senderContactInfo: "",
      date: "03-14-2024 02:12:32",
      type: ConversationTypeEnum.PHONE,
      key: 4,
      phoneNumber: "",
      emailAddress: "",
      eventResult: EventResultEnum.SUCCESS,
      attachments: []
    },
    {
      age: "1 min ago",
      direction: ConversationDirection.Outbound,
      content: {
        text: "External service wants to say something",
        externalUrl: "https://www.coxautoinc.com/"
      },
      senderName: "Some external dude",
      senderContactInfo: "",
      date: "03-14-2024 02:12:32",
      type: ConversationTypeEnum.EMAIL,
      key: 5,
      phoneNumber: "",
      emailAddress: "",
      eventResult: EventResultEnum.SUCCESS,
      attachments: []
    },
    {
      age: "seconds ago",
      direction: ConversationDirection.Inbound,
      content: {
        text: "Hello World"
      },
      senderName: "Mike Heady",
      senderContactInfo: "",
      date: "03-14-2024 02:12:32",
      type: ConversationTypeEnum.CHAT,
      key: 6,
      phoneNumber: "",
      emailAddress: "",
      eventResult: EventResultEnum.SUCCESS,
      attachments: []
    },
    {
      age: "1 min ago",
      direction: ConversationDirection.Inbound,
      content: {
        text: "External service wants to say something",
        externalUrl: "https://www.coxautoinc.com/"
      },
      senderName: "Some external dude",
      senderContactInfo: "testmail@mail.com",
      date: "03-14-2024 02:12:32",
      type: ConversationTypeEnum.EMAIL,
      key: 5,
      phoneNumber: "",
      emailAddress: "",
      eventResult: EventResultEnum.SUCCESS,
      attachments: []
    },
    {
      age: "1 min ago",
      direction: ConversationDirection.Outbound,
      content: {
        text: "External service wants to say something",
        externalUrl: "https://www.coxautoinc.com/"
      },
      senderName: "Some external dude",
      senderContactInfo: "testmail@mail.com",
      date: "03-14-2024 02:12:32",
      type: ConversationTypeEnum.EMAIL,
      key: 5,
      phoneNumber: "",
      emailAddress: "",
      eventResult: EventResultEnum.FAILED,
      attachments: []
    }
  ],
  recentContactText: "",
  recentContactEmail: "",
  xSignature: "MySignature001"
};

export const mockConversationContextProvider: ICustomerConversationContext = {
  activeListTab: ConversationTypeEnum.EMAIL,
  contactDetails: {
    consumerId: "abc-123",
    name: {
      first: "Jim",
      last: "Jam",
      fullName: "Jim Jam"
    },
    replyOptions: mockReplyOptions,
    contactChannels: {} as Record<ConversationTypeEnum, ContactDetail[]>
  },
  customerId: "abc-123",
  customerName: "Jim Jam",
  customerSystem: SystemOfRecord.COMMON,
  dealerCommunicationDetails: {
    dealerPhoneNumber: {
      phoneNumber: "555-5555"
    },
    userPhoneNumbers: [
      {
        phoneNumber: "555-5555",
        userFullName: "John Doe"
      }
    ]
  },
  disableReply: false,
  env: Environment.none,
  error: "",
  isGenerativeAIEnabled: false,
  occConfig: {
    username: "",
    disableReply: false,
    defaultTab: undefined,
    messageContent: undefined
  },
  replyOptions: mockReplyOptions,
  title: "",
  showCustomerInfo: true,
  setActiveListTab: () => "",
  setError: () => "",
  getUserName: () => "User Name"
};

export const mockConversationDetailsContextProvider: IConversationDetailsContext =
  {
    conversationItems: conversationDataProps.conversation,
    currentConversationType: ConversationTypeEnum.ALL,
    displayConversationItems: conversationDataProps.conversation,
    latestConversationItemType: ConversationTypeEnum.ALL,
    messageAttachments: attachmentScratchpad,
    messageCallContacted: "SPOKE",
    messageCallType: ConversationDirection.Inbound,
    recentContactEmail: "test123@example.com",
    recentContactText: "8165551245",
    replyText: "I'm replying!",
    replyTextSubject: "RE: Replying",
    selectedChannelValues: null,
    selectedConversationItem: conversationDataProps.conversation[0],
    selectedFromTextingPhoneNumber: "5555555555",
    selectedReplyOption: mockReplyOptions[0],
    xSignature: "MySignature001",
    setConversationItems: () => "",
    setCurrentConversationType: () => "",
    setDisplayConversationItems: () => "",
    setLatestConversationItemType: () => "",
    setMessageAttachments: () => [],
    setMessageCallContacted: () => "",
    setMessageCallType: () => "",
    setRecentContactEmail: () => "",
    setRecentContactText: () => "",
    setReplyText: () => "",
    setReplyTextSubject: () => "",
    setSelectedChannelValues: () => "",
    setSelectedConversationItem: () => "",
    setSelectedFromTextingPhoneNumber: () => null,
    setSelectedReplyOption: () => "",
    setXSignature: () => ""
  };
